<div *ngIf="(productFacade.colorStepState$ | async) as vm" class="color-step-container">
    <form class="k-form" [formGroup]="form">
        <h2 style="margin:0; font-weight:400">{{ 'lblSearchColor' | translate }}</h2>
        <div style="display:flex; gap:25px;">
            <kendo-formfield style="width:50%;margin-top:.5em">
                <kendo-textbox #sampleSearch
                               size="medium"
                               rounded="large"
                               [maxlength]="17"
                               placeholder="{{'lblBySampleNumber' | translate}}"
                               (input)="sampleSearch.value = sampleSearch.value.toUpperCase()"
                               formControlName="colorSampleSearch">
                    <ng-template kendoTextBoxSuffixTemplate>
                        <kendo-svg-icon [icon]="search"></kendo-svg-icon>
                    </ng-template>
                </kendo-textbox>
            </kendo-formfield>

            <kendo-formfield style="width: 50%; margin-top: .5em">
                <kendo-textbox size="medium"
                               rounded="large"
                               placeholder="{{ 'lblByColorNameCode' | translate }}"
                               formControlName="colorSearch">
                    <ng-template kendoTextBoxSuffixTemplate>
                        <kendo-svg-icon [icon]="search"></kendo-svg-icon>
                    </ng-template>
                </kendo-textbox>
            </kendo-formfield>
        </div>

        <h2 style="margin:0.75em 0;">{{ 'lblOr' | translate }}</h2>

        <div style="display:flex; flex-flow:row; gap:25px;">
            
            <div style="display:flex; flex-flow:column; gap:25px;width:50%">

                <div>
                    <h2 style="margin: 0; font-weight: 400; justify-content: flex-start;">{{ 'lblSelectChart' | translate }}</h2>
                    <kendo-dropdownlist formControlName="selectedChart"
                                        [data]="vm.colorCharts"
                                        [defaultItem]="{chartName:'Selection',chartId:''}"
                                        [loading]="vm?.colorCharts.length == 0"
                                        [svgIcon]="chevronDown"
                                        fillMode="outline"
                                        textField="chartName"
                                        valueField="chartId"
                                        [valuePrimitive]="true"
                                        (valueChange)="handleColorChartChange($event)">
                    </kendo-dropdownlist>
                </div>

                <div *ngIf="vm.colorFamilies.length != 0 && vm.selectedColorChart !== '' && !vm.isCustomColor">
                    <h2 style="margin-top: 0; margin-bottom: .5em; font-weight: 400">{{ 'lblSelectFamily' | translate }}</h2>
                    <kendo-dropdownlist [data]="vm.colorFamilies"
                                        [svgIcon]="chevronDown"
                                        fillMode="outline"
                                        textField="familyName"
                                        valueField="familyId"
                                        (valueChange)="handleColorFamilyChange($event)">
                    </kendo-dropdownlist>
                </div>
            </div>
            <div style="display:flex; flex-flow:column; gap:25px; width:50%;">

                <div>
                    <div style="height: 27px;"></div>
                    <button
                      kendoButton
                      style="width: 100%;"
                      class="align-left"
                      (click)="onNonDefinedColorButtonClick()"
                      [disabled]="isButtonDisabled()"
                    >
                      {{ 'lblNonDefinedColor' | translate }}
                    </button>
                </div>

                <div    *ngIf="globalStateService.tokenType.toUpperCase() === 'CNP' &&
                        form.controls['selectedChart'].value !== ''"
                        style="display: flex; justify-content: center; color: red; margin-top: 1em;" >
                        {{'msgCouleurExpress'| translate}}
                </div>
            </div>
        </div>



        <kendo-loader *ngIf="vm.searchingColor"
                      style="display:flex; align-items:center;flex-flow:column;width:100%;margin:25px;"
                      type="infinite-spinner"
                      themeColor="primary"
                      size="large">
        </kendo-loader>

        <div *ngIf="vm.isCustomColor" style="width:calc(50% - 12.5px);">

            <div style="margin-top:1em;">
                <kendo-floatinglabel text="{{ 'lblSelectBrand' | translate }}">
                    <kendo-dropdownlist [data]="vm.colorBrands"
                                        [svgIcon]="chevronDown"
                                        fillMode="outline"
                                        textField="brandName"
                                        valueField="brandId"
                                        [valuePrimitive]="true"
                                        formControlName="customColorBrandId">
                    </kendo-dropdownlist>
                </kendo-floatinglabel>
            </div>
            <div>
                <kendo-floatinglabel text="{{ 'lblColorName' | translate }}">
                    <kendo-textbox rounded="large"
                                   formControlName="customColorName">
                    </kendo-textbox>
                </kendo-floatinglabel>
            </div>
            <div>
                <kendo-floatinglabel text="{{ 'lblColorCode' | translate }}">
                    <kendo-textbox rounded="large"
                                   formControlName="customColorCode">
                    </kendo-textbox>
                </kendo-floatinglabel>
            </div>
        </div>
    </form>

    <div class="color-card-container">
        <ng-container *ngFor="let color of vm.colors">
            <app-color-card [color]=color Image="{{topImageUrl}}" [parentForm]="form"></app-color-card>
        </ng-container>
    </div>
    <button kendoButton
            class="cnp-button-primary"
            [disabled]="productFacade.disableNextStep$ | async"
            (click)="next()">
        {{ 'btnNextStep' | translate }}
    </button>
</div>
<kendo-dialog title="{{'titleConfirm'| translate}}"
              *ngIf="confirmDialogOpened"
              (close)="close('cancel')"
              [minWidth]="250"
              [width]="400">
    <p>{{'msg1Confirm'| translate}}</p>
    <p>{{'msg2Confirm'| translate}}</p>
    <p>
        <input kendoCheckBox type="checkbox" #chkRefuse [(ngModel)]="refuseSample" />
        <kendo-label class="k-checkbox-label"
                     [for]="chkRefuse"
                     text="{{'refuse'| translate}}"></kendo-label>
    </p>

    <kendo-dialog-actions>
        <button kendoButton [disabled]="!chkRefuse.checked" (click)="close('Continue')">{{'btnContinue'| translate}}</button>
        <button kendoButton (click)="close('Cancel')">{{'btnCancel'| translate}}</button>
    </kendo-dialog-actions>
</kendo-dialog>
