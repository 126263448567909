import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { filter, first, Subject } from 'rxjs';
import { Color } from '../../models/colors';
import { ProductFacade } from '../../services/product-facade';
import { DialogService } from "@progress/kendo-angular-dialog";
import { SVGIcon, chevronDownIcon, searchIcon } from "@progress/kendo-svg-icons";
import { GlobalStateService } from '../../../services/global-state.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-color-step',
  templateUrl: './color-step.component.html',
  styleUrls: ['./color-step.component.scss']
})
export class ColorStepComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  public search: SVGIcon = searchIcon;
  public chevronDown: SVGIcon = chevronDownIcon

  public confirmDialogOpened: boolean = false;
  public refuseSample: boolean = false;
    
  public topImageUrl: string;

  public form: FormGroup = new FormGroup({
    colorSearch: this.productFacade.buildSearchColorControl(),
    colorSampleSearch: this.productFacade.buildSearchColorSampleControl(),
    selectedColor: new FormControl<Color>(null),
    selectedChart: new FormControl<string>(""),
    customColorBrandId: new FormControl<string>(""),
    customColorCode: new FormControl<string>(""),
    customColorName: new FormControl<string>("")
  });

  constructor(
    public productFacade: ProductFacade,
    public globalStateService:GlobalStateService,
    public dialogService: DialogService,
    private translate: TranslateService) {

    

    this.form.controls["colorSearch"].valueChanges.subscribe(r => {
      if (r.length > 0) {
        this.form.controls["colorSampleSearch"].disable({ emitEvent: false });
        this.form.controls["selectedChart"].disable({ emitEvent: false });
      } else {
        this.form.controls["colorSampleSearch"].enable({ emitEvent: false });
        this.form.controls["selectedChart"].enable({ emitEvent: false });
      }
    });
    this.form.controls["colorSampleSearch"].valueChanges.subscribe(r => {
      if (r.length > 0) {
        this.form.controls["colorSearch"].disable({ emitEvent: false });
        this.form.controls["selectedChart"].disable({ emitEvent: false });
      } else {
        this.form.controls["colorSearch"].enable({ emitEvent: false });
        this.form.controls["selectedChart"].enable({ emitEvent: false });
      }
    });

    this.productFacade.colorCharts$.subscribe(r => {
      if (r && r.length > 0) {
        this.form.controls["selectedChart"].enable()
      }
      else {
        this.form.controls["selectedChart"].disable();
      }
    });
    this.form.controls["customColorBrandId"].valueChanges.subscribe(r => this.productFacade.updateCustomColorBrandId(r ?? ""));
    this.form.controls["customColorCode"].valueChanges.subscribe(r => this.productFacade.updateCustomColorCode(r));
    this.form.controls["customColorName"].valueChanges.subscribe(r => this.productFacade.updateCustomColorName(r));

    this.form.controls["selectedColor"].valueChanges.subscribe(r => this.productFacade.updateSelectedColor(r));

    this.productFacade.searchColorSampleResult$.subscribe(r => {
      if (r) {
        this.form.controls["customColorBrandId"].setValue(r.brandId);
        this.form.controls["customColorCode"].setValue(r.colorId);
        this.form.controls["customColorName"].setValue(r.colorName);
      }
    });
  }

  ngOnInit(): void {
    
    this.productFacade.fetchColorChart();
    this.productFacade.fetchColorBrands();
    this.productFacade.topImageUrl$.subscribe(r => {
      if (r === "") {
        this.topImageUrl = "assets/imgTextureDefault.png"
      }
      else {
        this.topImageUrl = r
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }


  public close(action: string) {
    this.confirmDialogOpened = false;
    if (action == 'Continue') {
      this.productFacade.updateColorStep(this.form.value.colorSampleSearch, this.form.value.customColorBrandId, this.form.value.customColorCode, this.form.value.customColorName);
    }
  }

  public next() {
    // It's an express Color; continue to next step
   
     if (this.form.value.customColorBrandId ||this.form.value.colorSampleSearch || this.form.value.selectedColor?.isExpressColor) {
      this.productFacade.updateColorStep(this.form.value.colorSampleSearch, this.form.value.customColorBrandId, this.form.value.customColorCode, this.form.value.customColorName);
    }
    // Prompt to confirm with client.
    else {
      this.confirmDialogOpened = true;
    }
  }
  public handleColorChartChange(value) {

    this.resetCustomColorFields();
    this.form.controls["selectedColor"].setValue(null);

    if (value !== "") {
      this.form.controls["colorSearch"].disable({ emitEvent: false });
      this.form.controls["colorSampleSearch"].disable({ emitEvent: false });
    }
    else {
      this.form.controls["colorSearch"].enable({ emitEvent: false });
      this.form.controls["colorSampleSearch"].enable({ emitEvent: false });
    }

    this.productFacade.updateSelectedColorChart(value);
  };

  private resetCustomColorFields() {
    this.form.controls["customColorBrandId"].setValue("");
    this.form.controls["customColorCode"].setValue("");
    this.form.controls["customColorName"].setValue("");
  }

  public handleColorFamilyChange(value) {
    this.productFacade.updateSelectedFamily(value);
    this.form.controls["selectedColor"].setValue(null);
  };

  public onNonDefinedColorButtonClick() {
    this.translate.get('maibecUndefined').subscribe((translatedValue: string) => {
      this.form.controls['colorSampleSearch'].setValue(translatedValue);

      const color = { 
        colorId: 'ND', 
        colorName: 'ND', 
        bgHexCode: 'ND', 
        textHexCode: 'ND',
        imageUrl: null, 
        brandId: null, 
        isExpressColor: false 
      };
  
      this.productFacade.colors$.pipe(
        filter((colors) => colors.length > 0),
        first()
      ).subscribe((colors) => {
        const availableColor = colors.find(c => c.colorId === color.colorId);
        if (availableColor) {
          this.form.controls['selectedColor'].setValue(availableColor);
          this.next();
        }
      });

    });
  } 
  public isButtonDisabled(): boolean {
    const colorSampleSearchFocused = document.activeElement === this.getElement('colorSampleSearch');
    const colorSearchFocused = document.activeElement === this.getElement('colorSearch');
  
    return (
      !!this.form.get('colorSampleSearch').value ||
      !!this.form.get('colorSearch').value ||
      !!this.form.get('selectedChart').value ||
      colorSampleSearchFocused ||
      colorSearchFocused
    );
  }
  private getElement(controlName: string): HTMLElement | null {
    return document.querySelector(`[formcontrolname="${controlName}"]`);
  }
}
