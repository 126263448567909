import { Component } from '@angular/core';
import { buildVersion, buildDate } from 'version'


@Component({
  selector: 'app-version-display',
  templateUrl: './version-display.component.html',
  styleUrl: './version-display.component.scss'
})
export class VersionDisplayComponent {
  version = buildVersion;
  date = buildDate;
}
