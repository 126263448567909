import { Component, Input, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Color } from '../../../models/colors';

@Component({
  selector: 'app-color-card',
  templateUrl: './color-card.component.html',
  styleUrls: ['./color-card.component.scss'],
})
export class ColorCardComponent implements OnInit, OnDestroy {

  @HostBinding('class') class = 'cnp-card';

  private subscriptions = new Subscription();

  readonly baseColorImagePath: string = `${environment.webApiUrl}/Images/Color`;

  focused: string;
  value: string;

  get imgSource(): string {
    return this.color.imageUrl ? `${this.baseColorImagePath}/${this.color.colorId}/${this.color.imageUrl}` : this.Image;
  }
  /**
 * Represents the parent form of the color step component.
 * Requires a mandatory input.
 */
  @Input()
  public parentForm: FormGroup;

  @Input()
  public color: Color

  @Input()
  public Image: string


  ngOnInit(): void {
    this.subscriptions.add(this.parentForm.get("selectedColor").valueChanges.subscribe((r: Color) => this.value = r?.colorId));
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onBlur() {
    this.focused = '';
  }

  public onFocus(value: string) {
    this.focused = value;
    this.parentForm.get('selectedColor').patchValue(this.color);
  }
}
